import '@wangeditor/editor/dist/css/style.css';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import { toFormData } from '@/utils/w';
export default {
  name: 'wEditor',
  components: {
    Editor,
    Toolbar
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    toolbarConfig: {
      type: [String, Object],
      default: () => {
        return {};
      }
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  computed: {
    content: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('update:modelValue', v);
      }
    }
  },
  data() {
    return {
      editor: null,
      insertFn: null,
      form: {},
      dialogFormVisible: false,
      editorConfig: {
        MENU_CONF: {
          uploadImage: {
            customBrowseAndUpload: insertFn => {
              this.insertFn = insertFn;
              this.form = {
                images: {
                  label: '详细介绍图片',
                  value: [],
                  formType: 'upload',
                  attrs: {
                    accept: '.jpg,.png,.jpeg',
                    limit: 20
                  },
                  type: 'array',
                  required: false
                }
              };
              this.$refs.wDialogForm.visible = true;
            }
          }
        }
      }
    };
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor);
    },
    customPaste(editor, e) {
      const clipboardData = e.clipboardData;
      let pastedData;
      if (clipboardData && clipboardData.items) {
        for (let i = 0; i < clipboardData.items.length; i++) {
          if (clipboardData.items[i].kind === 'file') {
            pastedData = clipboardData.items[i].getAsFile();
            break;
          }
        }
      }
      if (pastedData) {
        this.uploadImg(pastedData);
        e.preventDefault();
        return false;
      }
      return true;
    },
    getText() {
      return this.editor.getText();
    },
    insertImage(res) {
      for (const src of res.images) {
        this.insertFn(src);
      }
    },
    uploadImg(file, insertFn) {
      this.$w_fun.post('/upload/index', toFormData({
        filename: file.name,
        file: file
      }), {
        timeout: 60000
      }).then(({
        data
      }) => {
        if (insertFn) {
          insertFn(data.url);
        } else {
          this.editor.dangerouslyInsertHtml(`<img src="${data.url}">`);
        }
      }).catch(r => {
        console.log(r);
        this.$message.error(`上传 ${file.name} 失败`);
      });
    }
  },
  // 组件销毁时，及时销毁编辑器
  beforeDestroy() {
    if (this.editor !== null) return this.editor.destroy();
  }
};