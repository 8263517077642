var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", _vm._l(_vm.src_, function (s, i) {
    return _vm.src_.length > 0 ? _c("el-image", _vm._g(_vm._b({
      key: i,
      staticClass: "w-image-40 p2",
      attrs: {
        src: s,
        "preview-src-list": _vm.previewSrcList_,
        "initial-index": parseInt(i),
        fit: "contain"
      },
      scopedSlots: _vm._u([_vm._l(_vm.$slots, function (_slot, slotName) {
        return {
          key: slotName,
          fn: function () {
            return [_vm._t(slotName)];
          },
          proxy: true
        };
      })], null, true)
    }, "el-image", {
      ..._vm.$attrs,
      ..._vm.$props
    }, false), _vm.$listeners)) : _vm._e();
  }), 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };