import { post } from '@/libs/request';
export default {
  inheritAttrs: false,
  props: {
    // 模板下载地址
    template: {
      type: String,
      default: ''
    },
    // 触发导入地址
    import: {
      type: String,
      default: '/system/index/testImport'
    },
    timeout: {
      type: Number,
      default: 60000
    },
    // 请求附带参数
    data: {
      type: Object,
      default: () => ({})
    },
    form: {}
  },
  computed: {
    uploadData() {
      return {
        ...this.data,
        ...this.formData
      };
    }
  },
  data() {
    return {
      visible: false,
      fileList: [],
      percentage: 0,
      progressTip: '...等待选择文件',
      action: '',
      formData: {}
    };
  },
  methods: {
    async importFile(file, currentPage = 0) {
      try {
        const {
          data: {
            total,
            step,
            page,
            done
          }
        } = await post(this.import, {
          file,
          page: currentPage
        });
        if (done === 1) {
          this.progressTip = '<span style="color: green;">导入完成</span>';
          return this.percentage = 100;
        }
        this.progressTip = `...正在导入 ${step * page} / ${total}`;
        this.percentage = parseInt(step * page / total * 100);
        // this.importFile(file, page)
      } catch (e) {
        this.progressTip = '<span style="color: red;">...链接已中断</span>';
      }
    },
    download() {
      window.open(process.env.VUE_APP_PHP_API + this.template);
    },
    onChange(file, filelist) {
      this.progressTip = '...等待上传文件';
    },
    onProgress(file, fileList) {
      this.percentage = Math.floor(100 * fileList.filter(i => i.status === 'success').length / fileList.length);
      this.progressTip = fileList.filter(i => i.status === 'success').length + '/' + fileList.length;
    },
    async confirm() {
      try {
        this.progressTip = '...文件上传中';
        this.formData = await this.$refs.wForm.submit();
        await this.$nextTick();
        await this.$refs.upload.submit();
        this.visible = false;
        this.percentage = 100;
        this.progressTip = '导入完成';
        this.$emit('done');
        // await this.importFile(this.fileList[0].url)
      } catch (e) {}
    },
    beforeRemove() {
      this.percentage = 0;
      this.progressTip = '...等待选择文件';
    },
    setForm(form) {
      this.form = form || {};
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.action = this.import;
        this.fileList = [];
        this.percentage = 0;
        this.progressTip = '...等待选择文件';
      }
    }
  }
};