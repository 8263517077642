import { mapGetters } from 'vuex';
// import Breadcrumb from '@/components/Breadcrumb'
import Logo from './Sidebar/Logo';
import Hamburger from '@/components/Hamburger';
import router from '@/router';
export default {
  components: {
    // Breadcrumb,
    Hamburger,
    Logo
  },
  computed: {
    ...mapGetters(['permission_routes', 'sidebar', 'avatar']),
    isCollapse() {
      return this.$w_fun.isMobile() ? true : !this.sidebar.opened;
    },
    size_() {
      return this.$store.getters['size'] === 'small' ? '大' : '小';
    }
  },
  data() {
    return {
      form: {}
    };
  },
  methods: {
    backRouteFirstChildPath(currentRoute) {
      const getPath = (children, parentPath) => {
        for (const item of children) {
          if (item.children && item.children.length > 0 && item.meta.level < 4) {
            return getPath(item.children, parentPath + '/' + item.path);
          } else {
            return parentPath + '/' + item.path || '';
          }
        }
      };
      if (currentRoute.children && currentRoute.children.length > 0 && currentRoute.redirect === 'noRedirect') {
        return getPath(currentRoute.children, currentRoute.path);
      } else {
        return currentRoute.path;
      }
    },
    toggleSideBar() {
      this.$store.dispatch('toggleSideBar');
    },
    async logout() {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('logOut').then(() => {
          location.reload();
        });
      });
    },
    routerLinkGoto(to, type = 'push') {
      router[type](to);
    },
    changePassword() {
      this.form = {
        opassword: {
          label: '原密码',
          value: '',
          attrs: {
            showPassword: true
          }
        },
        password: {
          label: '新密码',
          value: '',
          attrs: {
            showPassword: true
          }
        },
        rpassword: {
          label: '确认密码',
          value: '',
          attrs: {
            showPassword: true
          }
        }
      };
      this.$refs.wDialogForm.visible = true;
    },
    changeSize(size) {
      this.$store.dispatch('setSize', size).then(() => {
        location.reload();
      });
    },
    reLogin() {
      this.$alert('修改成功，请重新登录', '重新登陆', {
        confirmButtonText: '确定',
        callback: action => {
          this.$store.dispatch('logOut').then(() => {
            location.reload();
          });
        }
      }).catch(() => {
        this.$store.dispatch('logOut').then(() => {
          location.reload();
        });
      });
    }
  }
};