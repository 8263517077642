import { dataToFile } from '@/utils/w';
export default {
  name: 'wImage',
  props: {
    src: {
      type: String,
      default: ''
    },
    fit: {
      type: String,
      default: 'cover'
    },
    alt: {
      type: String,
      default: ''
    },
    previewSrcList: {
      type: [String, Object, Array],
      default: ''
    }
  },
  computed: {
    src_() {
      const src = dataToFile(this.src)[0];
      return src ? [src.url] : this.previewSrcList_;
    },
    previewSrcList_() {
      return dataToFile(this.previewSrcList).map(({
        url
      }) => url);
    }
  }
};